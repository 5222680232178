<template>
  <div class="tw-my-12">
    <v-row v-if="activities && activities.length">
      <v-col class="scroll " cols="12" md="7" sm="12">
        <div v-for="(activity,i) in activities" :key="i" class="activity-wrapper tw-p-6  tw-mb-4">
          <v-row class="tw-flex activity" no-gutters>
            <v-col class="tw-mb-3 md:tw-mb-0" cols="12" md="5" sm="12">
              <h1>{{ activity.application_status }}</h1>
            </v-col>
            <v-col cols="12" md="7" sm="12">
              <h1 class="tw-mb-2 md:tw-mb-0">{{ activity.message }}</h1>
              <span>{{ formatDate(activity.date_created) }} {{ '  ' + formatTime(activity.date_created) }}</span>
            </v-col>

          </v-row>

        </div>
      </v-col>
    </v-row>
    <div v-else-if="loading">
      <Loader :loading="loading"/>
    </div>
    <div v-else class="tw-flex tw-my-8 tw-justify-center ">
      <small>No Activity for this candidate yet</small>
    </div>
  </div>
</template>

<script>
import {getActivityLog} from "@/services/api/APIService";
import moment from "moment";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";

export default {
  name: "ActivityLogs",
  components: {Loader},
  data() {
    return {
      activities: [],
      loading:false
    }
  },
  mounted() {
    this.getAllActivityLog()
  },
  computed: {
    formatDate() {
      return function (date) {
        return moment(date).format('Do MMM, YYYY');

      }
    },
    formatTime() {
      return function (time) {
        if(time){
          return moment(time.split("T")[1], "HH:mm:ss").format("hh:mm A");

        }

      }
    },
  },
  methods: {
    getAllActivityLog() {
      this.loading=true
      getActivityLog(this.$route.query.email, this.$route.query.jobId)
          .then(res => {
            console.log(res.data)
            this.activities = res.data
          })
          .catch(err => {
            console.log(err)
          })
      .finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.activity-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;


}

.activity h1 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;

}

.activity span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
  margin-top: 5px;
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

</style>
